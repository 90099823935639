<template>
  <tp-modal
    v-slot="{ close }"
    :width="'720px'"
    max-width="90%"
    name="modal-detail-debt-adjustment"
    @open="onFetch"
  >
    <v-toolbar
      class="px-5 pb-0 align-start"
      extension-height="40px"
      flat
      height="56x"
    >
      <v-toolbar-title class="font-weight-bold">
        Điều chỉnh công nợ {{ customer.code }} - {{ customer.name }} ({{
          customer.phone
        }})
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-container class="grey lighten-3 pa-5 black--text" fluid>
      <v-card class="pa-6" flat>
        <v-row v-if="data">
          <v-col class="pa-0 px-2" cols="12" md="12">
            <div>
              <span class="font-weight-bold">Thời gian:</span>
              {{ formatDate(data.updated_at) }}
            </div>
          </v-col>

          <v-col class="pa-0 mt-4 px-2" cols="12" md="6">
            <div class="font-weight-bold mb-2">Giá trị điều chỉnh</div>

            <currency-input
              v-model="data.amount"
              class="text-body-1 pt-0 mt-0"
              dense
              outlined
              placeholder="Giá trị"
              readonly
              single-line
              suffix="đ"
            ></currency-input>
          </v-col>

          <v-col class="pa-0 mt-4 px-2" cols="12" md="6"></v-col>

          <v-col class="pa-0 mt-2 px-2" cols="12" md="12">
            <div class="font-weight-bold">
              Mã chứng từ (Không bắt buộc)
            </div>

            <span class="text-body-2">
              Chứng từ điền vào sẽ được điều chỉnh nợ tương ứng với giá trị điều
              chỉnh.
            </span>

            <div class="mt-4 d-flex">
              <v-select
                v-model="data.model_type"
                :items="MODEL_TYPES"
                class="w-1/2"
                dense
                item-text="text"
                item-value="value"
                no-data-text="Không có dữ liệu"
                outlined
                placeholder="Chọn loại chứng từ"
                readonly
              ></v-select>

              <v-select
                v-model="data.model_code"
                :items="[data.model_code]"
                class="ml-4 w-1/2"
                dense
                no-data-text="Không có dữ liệu"
                outlined
                placeholder="Chọn chứng từ"
                readonly
              ></v-select>
            </div>
          </v-col>

          <v-col class="pa-0 mt-2 px-2" cols="12" md="12">
            <div class="font-weight-bold mb-2">
              Lý do (bắt buộc)
            </div>

            <v-textarea
              v-model="data.reason"
              class="text-body-1"
              dense
              outlined
              placeholder="Nhập lý do điều chỉnh"
              readonly
              required
              rows="2"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </tp-modal>
</template>

<script>
import { defineComponent } from "vue";
import dayjs from "dayjs";
import { useFetchData } from "@thinkvn/composables/lib/useFetchData";

import { LIST_MODEL_TYPES } from "@/core/constant";
import CurrencyInput from "@/core/components/general/CurrencyInput/index.vue";

import { getDetailDebtAdjustment } from "@/core/service/debt.service";
import { createVuexHelpers } from "@/core/composables";

export default defineComponent({
  components: { CurrencyInput },

  setup() {
    const { useGetters } = createVuexHelpers();
    const { customer } = useGetters("CUSTOMER", ["customer"]);
    const { fetch, data } = useFetchData(getDetailDebtAdjustment, {
      transform: response => response.data
    });

    const onFetch = params => {
      fetch(params.id);
    };

    return {
      customer,
      onFetch,
      fetch,
      data
    };
  },

  data() {
    return {
      MODEL_TYPES: [
        { text: "Hoá đơn bán", value: LIST_MODEL_TYPES.HOA_DON_BAN },
        { text: "Đơn hàng", value: LIST_MODEL_TYPES.ORDER },
        { text: "Phiếu trả hàng", value: LIST_MODEL_TYPES.KHACH_TRA_HANG }
      ]
    };
  },

  methods: {
    formatDate(date) {
      return dayjs(date).format("HH:mm DD/MM/YYYY");
    }
  }
});
</script>
