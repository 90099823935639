<template>
  <a :href="to" target="_blank">
    <v-card class="pa-6 d-flex flex-col item-center" flat>
      <slot name="icon"></slot>

      <div class="mt-1 text-center font-weight-bold">
        <slot></slot>
      </div>
    </v-card>
  </a>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: "#"
    }
  }
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
