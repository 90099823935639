<template>
  <div>
    <card-address
      class="mb-5"
      v-for="(item, index) in customerAddresses"
      :key="index"
      :edit-item-index="editItemIndex"
      :is-edit-mode="isEditMode"
      :item="item"
      :item-index="index"
      @updateEditItemIndex="updateEditItemIndex($event)"
      @updateIsEditMode="updateIsEditMode($event)"
    ></card-address>
    <v-btn
      class="rounded-lg mr-2"
      depressed
      color="primary"
      @click="addEmptyCustomerAddress()"
    >
      Thêm địa chỉ
    </v-btn>
  </div>
</template>

<script>
import CardAddress from "./CardAddress";

export default {
  components: {
    CardAddress
  },
  props: {
    editItemIndex: {
      type: Number
    },
    isEditMode: {
      type: Boolean
    }
  },
  computed: {
    customerAddresses() {
      return this.$store.getters["CUSTOMER/customerAddresses"];
    },
    customerAddress() {
      return this.$store.getters["CUSTOMER/customerAddress"];
    },
    customer() {
      return this.$store.getters["CUSTOMER/customer"];
    }
  },
  methods: {
    async addEmptyCustomerAddress() {
      console.log(this.customer.phone);
      await this.customerAddresses.push({
        of_user_id: null,
        name: this.customer.name,
        phone: this.customer.phone,
        tinh_thanhpho: null,
        quan_huyen: null,
        phuong_xa: null,
        so_nha: null
      });

      await this.$store.dispatch("CUSTOMER/setCustomerAddress", {
        of_user_id: null,
        name: this.customer.name,
        phone: this.customer.phone,
        tinh_thanhpho: null,
        quan_huyen: null,
        phuong_xa: null,
        so_nha: null
      });

      // Check empty item
      if (
        this.editItemIndex &&
        !this.customerAddresses[this.editItemIndex].id
      ) {
        this.customerAddresses.splice(this.editItemIndex, 1);
      }

      this.$emit("updateIsEditAddressMode", true);
      this.$emit(
        "updateEditAddressItemIndex",
        this.customerAddresses.length - 1
      );
    },

    updateEditItemIndex(index) {
      // Check empty item
      if (
        this.editItemIndex &&
        !this.customerAddresses[this.editItemIndex].id
      ) {
        this.customerAddresses.splice(this.editItemIndex, 1);
      }

      this.$emit("updateEditAddressItemIndex", index);
    },

    updateIsEditMode(val) {
      this.$emit("updateIsEditAddressMode", val);
    }
  }
};
</script>
