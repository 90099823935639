import Api from "@/core/apis";

export const suggestDebtAdjustment = params => {
  return Api().$get("debt-adjustment/search-model", { params });
};

export const updateDebtAdjustment = params => {
  return Api().$post("debt-adjustment", params);
};

export const getDetailDebtAdjustment = id => {
  return Api().$get(`debt-adjustment/detail/${id}`);
};
