<template>
  <v-dialog v-model="visible" :width="'720px'" max-width="90%">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }"></slot>
    </template>

    <v-toolbar
      class="px-5 pb-0 align-start"
      extension-height="40px"
      flat
      height="56x"
    >
      <v-toolbar-title class="font-weight-bold">
        Điều chỉnh công nợ {{ customer.code }} - {{ customer.name }} ({{
          customer.phone
        }})
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn class="mr-2" color="primary" depressed @click="mutate">
        Xác nhận
      </v-btn>

      <v-btn icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-container class="grey lighten-3 pa-5 black--text" fluid>
      <v-card class="pa-6" flat>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col class="pa-0 px-2" cols="12" md="12">
              <div>
                <span class="font-weight-bold">Số dư hiện tại:</span>
                {{ data.deb_amount | formatCurrency }}
              </div>
            </v-col>

            <v-col class="pa-0 mt-4 px-2" cols="12" md="6">
              <div class="font-weight-bold mb-2">Giá trị điều chỉnh</div>

              <currency-input
                v-model="state.amount"
                :rules="amountRules"
                class="text-body-1 pt-0 mt-0"
                dense
                outlined
                placeholder="Giá trị"
                single-line
                suffix="đ"
              ></currency-input>
            </v-col>

            <v-col class="pa-0 mt-4 px-2" cols="12" md="6">
              <div class="font-weight-bold mb-2">Số dư sau điều chỉnh</div>

              <div
                :class="
                  amountAfterAdjustment === 0 ? 'green--text' : 'red--text'
                "
                class="mt-4  lighten-3"
              >
                {{ amountAfterAdjustment | formatCurrency }}
              </div>
            </v-col>

            <v-col class="pa-0 mt-2 px-2" cols="12" md="12">
              <div class="font-weight-bold">
                Mã chứng từ (Không bắt buộc)
              </div>

              <span class="text-body-2">
                Chứng từ điền vào sẽ được điều chỉnh nợ tương ứng với giá trị
                điều chỉnh.
              </span>

              <div class="mt-4 d-flex">
                <v-select
                  v-model="state.model_type"
                  :items="MODEL_TYPES"
                  class="w-1/2"
                  dense
                  item-text="text"
                  item-value="value"
                  no-data-text="Không có dữ liệu"
                  outlined
                  placeholder="Chọn loại chứng từ"
                ></v-select>

                <v-select
                  v-model="state.model_id"
                  :items="suggestionDebtAdjustments"
                  class="ml-4 w-1/2"
                  dense
                  item-text="code"
                  item-value="id"
                  no-data-text="Không có dữ liệu"
                  outlined
                  placeholder="Chọn chứng từ"
                ></v-select>
              </div>
            </v-col>

            <v-col class="pa-0 mt-4 px-2" cols="12" md="6">
              <div class="font-weight-bold mb-2">Thời gian điều chỉnh</div>

              <input-date-time
                v-model="state.created_at"
                placeholder="Nhập thời gian điều chỉnh"
              ></input-date-time>
            </v-col>

            <v-col class="pa-0 mt-2 px-2" cols="12" md="12">
              <div class="font-weight-bold mb-2">
                Lý do (bắt buộc)
              </div>

              <v-textarea
                v-model="state.reason"
                :rules="[v => !!v || 'Vui lòng nhập lý do']"
                class="text-body-1"
                dense
                outlined
                placeholder="Nhập lý do điều chỉnh"
                required
                rows="2"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import {
  computed,
  defineComponent,
  getCurrentInstance,
  reactive,
  watch,
  ref
} from "vue";
import { useVisible } from "@thinkvn/composables/lib/useVisible";
import { useFetchData } from "@thinkvn/composables/lib/useFetchData";
import { useMutation } from "@thinkvn/composables/lib/useMutation";
import { createVuexHelpers } from "@/core/composables";
import {
  suggestDebtAdjustment,
  updateDebtAdjustment
} from "@/core/service/debt.service";
import { LIST_MODEL_TYPES } from "@/core/constant";
import CurrencyInput from "@/core/components/general/CurrencyInput/index.vue";
import { formatCurrency } from "@vt7/utils";
import InputDateTime from "@/core/components/form/input-date-time.vue";
import dayjs from "dayjs";

export default defineComponent({
  components: { InputDateTime, CurrencyInput },
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const instance = getCurrentInstance();
    const { visible, close } = useVisible();
    const { useGetters } = createVuexHelpers();
    const { customer } = useGetters("CUSTOMER", ["customer"]);

    const form = ref();
    const state = reactive({
      amount: 0,
      model_type: "",
      model_id: 0,
      reason: "",
      customer_id: customer.value.id,
      created_at: dayjs().format("YYYY-MM-DD HH:mm")
    });

    const amountAfterAdjustment = computed(() => {
      return props.data.deb_amount + state.amount;
    });

    const {
      data: suggestionDebtAdjustments,
      fetch: fetchSuggestion
    } = useFetchData(
      () =>
        suggestDebtAdjustment({
          model_type: state.model_type,
          customer_id: customer.value.id
        }),
      {
        initialData: []
      }
    );

    const { mutate } = useMutation(() => updateDebtAdjustment(state), {
      async onValidate() {
        const isValid = form.value.validate();

        if (!isValid) return;

        return new Promise(resolve => {
          instance.proxy.$modal.show({
            name: "modal-alert",
            payload: {
              title: `Xác nhận điều chỉnh công nợ của ${
                customer.value.name
              } ${formatCurrency(state.amount)}đ`,
              message: `Số dư của khách hàng sau điều chỉnh là <span class="red--text lighten-3 font-weight-medium">${formatCurrency(
                amountAfterAdjustment.value
              )}đ</span>. Hành động này sẽ không thể hoàn tác, bạn có chắc chắn muốn tiếp tục?`,
              confirmBtn: {
                text: "Xác nhận",
                color: "primary",
                onClickHandler: () => resolve(true)
              },
              onCancelHandler: () => resolve(false)
            }
          });
        });
      },
      onSuccess() {
        close();
        emit("on-success");
      }
    });

    watch(
      () => state.model_type,
      value => {
        if (!value) return;

        fetchSuggestion();
      }
    );

    return {
      customer,
      visible,
      close,
      state,
      form,
      amountAfterAdjustment,
      suggestionDebtAdjustments,
      mutate
    };
  },

  data() {
    return {
      MODEL_TYPES: [
        { text: "Hoá đơn bán", value: LIST_MODEL_TYPES.HOA_DON_BAN },
        { text: "Đơn hàng", value: LIST_MODEL_TYPES.ORDER },
        { text: "Phiếu trả hàng", value: LIST_MODEL_TYPES.KHACH_TRA_HANG }
      ],
      amountRules: [
        v => !!v || "Vui lòng nhập số tiền",
        v => (v && v != 0) || "Số tiền khác 0"
      ]
    };
  }
});
</script>
