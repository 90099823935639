<template>
  <div>
    <form-bank-account
      v-for="item in customerBankAccounts"
      :bank-account="item"
      class="mb-4"
      :key="item.id"
      :object-id="customer.id"
      :object-type="BANK_ACCOUNT_OBJECT_TYPE.USER"
    />
    <form-bank-account
      :bank-account="emptyCustomerBankAccount"
      input-mode
      :object-id="customer.id"
      :object-type="BANK_ACCOUNT_OBJECT_TYPE.USER"
    />
  </div>
</template>

<script>
import FormBankAccount from "@/core/components/form/FormBankAccount/index.vue";
import {
  BANK_ACCOUNT_OBJECT_TYPE,
  INIT_OBJECT_BANK_ACCOUNT_VALUE
} from "@/core/constant";

export default {
  components: { FormBankAccount },

  data() {
    return {
      BANK_ACCOUNT_OBJECT_TYPE: BANK_ACCOUNT_OBJECT_TYPE,
      emptyCustomerBankAccount: JSON.parse(
        JSON.stringify(INIT_OBJECT_BANK_ACCOUNT_VALUE)
      )
    };
  },

  computed: {
    customer() {
      return this.$store.getters["CUSTOMER/customer"];
    },

    customerBankAccounts() {
      return this.$store.getters["BANK_ACCOUNT/objectBankAccounts"];
    }
  }
};
</script>
