var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"text-body-1",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"tp-table-scroll tp-table__row-pointer rounded-0 px-3 py-2",attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.isFetching,"disable-pagination":"","disable-sort":"","hide-default-footer":"","loading-text":"Đang tải dữ liệu","no-data-text":"Không có dữ liệu"},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
var item = ref.item;
return [(item.value >= 0)?_c('span',{staticClass:"lighten-3 green--text"},[_vm._v(" +"+_vm._s(_vm._f("formatCurrency")(item.value))+" ")]):_c('span',{staticClass:"lighten-3 red--text"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.value))+" ")])]}},{key:"item.deb_amount",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(_vm._s(_vm._f("formatCurrency")(item.deb_amount)))]),(index === 0)?_c('modal-debt-adjustment',{attrs:{"data":item},on:{"on-success":_vm.fetch},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)}):_vm._e()],1)]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [(item.type === 'HoaDonBan')?_c('button',{staticClass:"primary--text",on:{"click":function($event){return _vm.viewDetailSaleReceipt(item.id)}}},[_vm._v(" "+_vm._s(_vm.getCodeName(item.code))+" ")]):(item.type === 'DebtAdjustment')?_c('button',{staticClass:"primary--text",on:{"click":function($event){return _vm.openDetailDebt(item.id)}}},[_vm._v(" "+_vm._s(_vm.getCodeName(item.code))+" ")]):(item.type === 'KhachTraHang')?_c('button',{staticClass:"primary--text",on:{"click":function($event){return _vm.viewDetailCustomerReturn(item.id)}}},[_vm._v(" "+_vm._s(_vm.getCodeName(item.code))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.getCodeName(item.code))+" ")])]}},{key:"item.code_link",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.code_link || "")+" ")])]}}],null,true)}),_c('modal-detail-debt-adjustment')],1)}
var staticRenderFns = []

export { render, staticRenderFns }