<template>
  <v-text-field
    ref="inputRef"
    v-model="formattedValue"
    v-bind="$attrs"
    v-on="omit($listeners, 'input')"
  ></v-text-field>
</template>

<script>
import { defineComponent, reactive, watch } from "vue";
import { omit } from "@vt7/utils";
import { useCurrencyInput } from "vue-currency-input";

export default defineComponent({
  name: "CurrencyInput",

  props: {
    value: {
      type: [Number, String],
      default: ""
    },
    valueRange: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const options = reactive({
      locale: "vi-VN",
      currency: "VND",
      currencyDisplay: "hidden",
      valueRange: props.valueRange,
      hideCurrencySymbolOnFocus: false,
      hideGroupingSeparatorOnFocus: false,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      useGrouping: true,
      accountingSign: false
    });

    const {
      inputRef,
      formattedValue,
      setValue,
      numberValue
    } = useCurrencyInput(options);

    watch(
      () => props.value,
      value => {
        setValue(value);
      },
      {
        immediate: true
      }
    );

    return {
      inputRef,
      formattedValue,
      numberValue,
      omit
    };
  }
});
</script>
