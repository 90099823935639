<template>
  <v-card class="text-body-1" flat>
    <v-data-table
      :headers="headers"
      :items="data"
      :loading="isFetching"
      class="tp-table-scroll tp-table__row-pointer rounded-0 px-3 py-2"
      disable-pagination
      disable-sort
      hide-default-footer
      loading-text="Đang tải dữ liệu"
      no-data-text="Không có dữ liệu"
    >
      <template v-slot:[`item.value`]="{ item }">
        <span v-if="item.value >= 0" class="lighten-3 green--text">
          +{{ item.value | formatCurrency }}
        </span>

        <span v-else class="lighten-3 red--text">
          {{ item.value | formatCurrency }}
        </span>
      </template>
      <template v-slot:[`item.deb_amount`]="{ item, index }">
        <div class="d-flex align-center">
          <span>{{ item.deb_amount | formatCurrency }}</span>

          <modal-debt-adjustment
            v-if="index === 0"
            :data="item"
            @on-success="fetch"
          >
            <template #activator="{on, attrs}">
              <v-btn color="primary" icon small v-bind="attrs" v-on="on">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </modal-debt-adjustment>
        </div>
      </template>
      <template v-slot:[`item.code`]="{ item }">
        <button
          v-if="item.type === 'HoaDonBan'"
          class="primary--text"
          @click="viewDetailSaleReceipt(item.id)"
        >
          {{ getCodeName(item.code) }}
        </button>
        <button
          v-else-if="item.type === 'DebtAdjustment'"
          class="primary--text"
          @click="openDetailDebt(item.id)"
        >
          {{ getCodeName(item.code) }}
        </button>
        <button
          v-else-if="item.type === 'KhachTraHang'"
          class="primary--text"
          @click="viewDetailCustomerReturn(item.id)"
        >
          {{ getCodeName(item.code) }}
        </button>
        <span v-else>
          {{ getCodeName(item.code) }}
        </span>
      </template>
      <template v-slot:[`item.code_link`]="{ item }">
        <span>
          {{ item.code_link || "" }}
        </span>
      </template>
    </v-data-table>

    <modal-detail-debt-adjustment></modal-detail-debt-adjustment>
  </v-card>
</template>

<script>
import { defineComponent, markRaw, watch } from "vue";
import { useFetchData } from "@thinkvn/composables/lib/useFetchData.mjs";

import BankAccount from "@/core/service/bank-account.service";
import { createVuexHelpers } from "@/core/composables";

import ModalDebtAdjustment from "./ModalDebtAdjustment.vue";
import ModalDetailDebtAdjustment from "./ModalDetailDebtAdjustment.vue";

export default defineComponent({
  components: { ModalDebtAdjustment, ModalDetailDebtAdjustment },

  setup() {
    const { useGetters } = createVuexHelpers();
    const { customer } = useGetters("CUSTOMER", ["customer"]);
    const { fetch, data, isFetching } = useFetchData(
      () => BankAccount.getDebtHistory(customer.value.id),
      { transform: res => res.data }
    );

    watch(
      () => customer.value.id,
      () => {
        fetch();
      },
      {
        immediate: true
      }
    );

    return {
      data,
      fetch,
      isFetching,
      headers: markRaw([
        {
          text: "Thời gian",
          align: "start",
          value: "created_at"
        },
        {
          text: "Loại",
          value: "type_label"
        },
        {
          text: "Chứng từ gốc",
          value: "code"
        },
        {
          text: "Chứng từ liên quan",
          value: "code_link"
        },
        {
          text: "Giá trị",
          value: "value"
        },
        {
          text: "Số dư",
          value: "deb_amount"
        }
      ])
    };
  },
  methods: {
    getCodeName(code) {
      const codes = code.split("-");

      return codes.length ? codes[0] : code;
    },
    openDetailDebt(id) {
      this.$modal.show({
        name: "modal-detail-debt-adjustment",
        payload: {
          id
        }
      });
    },
    async viewDetailSaleReceipt(id) {
      await this.$store.dispatch("SALE_RECEIPT/getSaleReceiptById", id);

      const saleReceipts = this.$store.getters["SALE_RECEIPT/saleReceipts"];
      const index = saleReceipts.findIndex(item => item.id === id);

      this.$modal.show({
        name: "modal-sale-receipts",
        payload: { index }
      });

      this.$store.dispatch(
        "SALE_RECEIPT/getSaleReceiptPaymentTransactions",
        id
      );

      this.$store.dispatch(
        "INVOICE_PARTICIPANT/getInvoiceParticipantsOfInvoice",
        {
          modelName: "App\\Models\\HoaDonBan",
          modelId: id
        }
      );
    },
    async viewDetailCustomerReturn(id) {
      await this.$store.dispatch("CUSTOMER_RETURN/getCustomerReturnById", id);

      await this.$store.dispatch(
        "CUSTOMER_RETURN/getCustomerReturnTransactions",
        id
      );

      this.$modal.show({
        name: "modal-customer-return"
      });
    }
  }
});
</script>
