<template>
  <div class="d-flex flex-wrap gap-5">
    <transaction-item
      v-for="item in transactionTypes"
      :key="item.id"
      class="transaction-item"
      :to="item.getUrl(customer.phone)"
    >
      <template #icon>
        <v-icon :color="item.color" large>
          {{ item.icon }}
        </v-icon>
      </template>

      <template #default>
        <div class="font-weight-bold">{{ item.name }}</div>
      </template>
    </transaction-item>
  </div>
</template>

<script>
import TransactionItem from "./TransactionItem.vue";
export default {
  components: {
    TransactionItem
  },

  data() {
    return {
      CMS_BASE_URL: process.env.VUE_APP_BASE_URL,
      transactionTypes: [
        {
          id: 1,
          name: "Đơn hàng",
          icon: "mdi-cart",
          getUrl: phoneNumber => {
            return `${this.CMS_BASE_URL}/#/transaction/orders?tabs=result&itemPerPage=50&currentPage=1&searchKey=${phoneNumber}&searchType=1`;
          },
          color: "blue"
        },
        {
          id: 2,
          name: "Bảo hành",
          icon: "mdi-shield-check",
          getUrl: phoneNumber => {
            return `${this.CMS_BASE_URL}/#/guarantee/vouchers/list?type=1&cancel_status=0&search=${phoneNumber}&search_type=1&limit=50&page=1`;
          },
          color: "green"
        },
        {
          id: 3,
          name: "Đổi trả",
          icon: "mdi-reply-all",
          getUrl: phoneNumber => {
            return `${this.CMS_BASE_URL}/#/transaction/customer-return?search=${phoneNumber}&search_key=1&limit=50&page=1`;
          },
          color: "purple"
        },
        {
          id: 4,
          name: "Sửa chữa",
          icon: "mdi-hammer-wrench",
          getUrl: phoneNumber => {
            return `${this.CMS_BASE_URL}/#/guarantee/vouchers/list?type=2&cancel_status=0&search=${phoneNumber}&search_type=1&limit=50&page=1`;
          },
          color: "orange"
        }
      ]
    };
  },

  computed: {
    customer() {
      return this.$store.getters["CUSTOMER/customer"];
    }
  }
};
</script>

<style scoped>
.transaction-item {
  flex-basis: calc(50% - 20px);
  margin: 10px;
}
</style>
